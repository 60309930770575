<template>
  <div>
    <div class="col-12 my-4">
      <aad-back></aad-back>
    </div>
    <transition name="fade" mode="out-in">
      <aad-loader key="1" v-if="isLoading"></aad-loader>
      <aad-loader
        key="2"
        v-else-if="surveySubmitting"
        :message="
          surveyTitle.toUpperCase().includes('OVERALL MEETING')
            ? 'Submitting Overall Evaluation'
            : 'Submitting CME Claim, Please Wait'
        "
      ></aad-loader>
      <div key="3" v-else-if="surveySubmitted" class="w-100 py-4">
        <div>
          <div v-if="surveyTitle" v-html="surveyTitle"></div>
          <div v-else>
            <aad-title>Overall Feedback</aad-title>
          </div>
        </div>
        <div class="text-center">
          <aad-icon
            name="checkmark-circle-filled"
            class="submitted-icon mb-2"
          ></aad-icon>
          <h3 v-if="!surveyTitle.toUpperCase().includes('OVERALL MEETING')">
            Your CME Claim has been successfully submitted!
          </h3>
          <h3 v-else>
            Your overall evaluation has been successfully submitted!
          </h3>
          <button
            @click="cancelTimeoutGoToCME"
            class="btn btn-primary-solid mr-2"
          >
            View All CME
          </button>
          <button
            @click="cancelTimeoutGoToSessionPage"
            class="btn btn-primary-solid"
            v-if="this.sessionCode.toUpperCase() != 'OVERALL'"
          >
            View Session
          </button>
        </div>
      </div>
      <div key="4" v-else>
        <div v-if="surveyTitle" v-html="surveyTitle"></div>
        <div class="alert alert-danger my-2" role="alert" v-if="errorMessage">
          <span class="font-weight-bold"> Error: </span> {{ errorMessage }}
        </div>
        <form
          v-html="surveyHtml"
          ref="survey"
          @submit.prevent="submitCME"
        ></form>
      </div>
    </transition>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import AadLoader from "../components/AadLoader.vue";
import Analytics from "@/mixins/analytics.js";

export default {
  data() {
    return {
      sessionCode: this.$route.params.sessionCode, // Get sessionCode from the url
      surveySubmitting: false, // Used for loading animation
      surveySubmitted: false, // Used for showing confirmation
      isLoading: true, // Used to pop loading screen
      sessionName: "",
      errorMessage: "",
      surveyHtml: "",
      timeout: "", // Used to be able to clear timeout to prevent double nav
    };
  },
  metaInfo() {
    return {
      title: this.sessionName ? this.sessionName : "CME Claim", // Used to set <title> attribute
    };
  },
  mounted() {
    console.log(this.sessionCode, "Session code");
    this.getCMESurveyFromServer(this.sessionCode);
  },
  methods: {
    // Load Survery from Server
    getCMESurveyFromServer(sessionCode) {
      // If no registrant code or hash, throw error and prompt to logout and login
      if (
        this.loggedInUser == null ||
        this.loggedInUser.registrationHash == undefined ||
        this.loggedInUser.registrationCode == undefined
      ) {
        this.throwError(
          "Registrant info not available. Please try logging out and back in! Contact us for support if this issue persists."
        );
        this.isLoading = false;
        return;
      }

      console.log(
        `https://meetings.aad.org/Evaluations/GateWay/Session/${this.getMeetingCode}/${this.loggedInUser.registrationCode}/${this.loggedInUser.registrantHash}?sessioncode=${sessionCode}&unwrap=true`
      );
      fetch(
        `https://meetings.aad.org/Evaluations/GateWay/Session/${this.getMeetingCode}/${this.loggedInUser.registrationCode}/${this.loggedInUser.registrationHash}?sessioncode=${sessionCode}&unwrap=true`
      )
        .then((resp) => resp.text())
        .then((html) => {
          const parser = new DOMParser();
          const doc = parser.parseFromString(html, "text/html");
          const cmeForm = doc.getElementById("cme-claim-form");
          const cmeTitle = doc.getElementById("cme-claim-title");

          this.surveyHtml = cmeForm.innerHTML;
          this.surveyTitle = cmeTitle.innerHTML;
          this.isLoading = false;
        })
        .catch(() => {
          this.errorMessage = "Something went wrong.";
          this.isLoading = false;
        });
    },

    submitCME(event) {
      event.preventDefault();

      // Submit to server
      this.surveySubmitting = true;
      this.surveySubmitted = false;

      const cmeForm = this.$refs.survey;

      fetch(
        `https://meetings.aad.org/Evaluations/GateWay/Session/${this.getMeetingCode}/${this.loggedInUser.registrationCode}/${this.loggedInUser.registrationHash}?sessioncode=${this.sessionCode}&unwrap=true`,
        {
          method: "POST",
          body: new FormData(cmeForm),
        }
      )
        .then((response) => Promise.all([response.status, response.text()]))
        .then(([status, html]) => {
          this.surveySubmitting = false;

          // Check if the server returned a 200 OK
          if (status == 200) {
            this.surveySubmitted = true;

            // Log to analytics
            Analytics.logEvent("aad_cme_click", {
              action_type: "submit",
              session_code:
                this.sessionCode.toUpperCase() == "OVERALL"
                  ? "OVERALL"
                  : this.sessionCode,
              screen_origin: "cme_survey",
            });

            // Redirect
            // Save refence because this won't work in function
            let router = this.$router;
            this.timeout = setTimeout(function () {
              // Log to analytics
              Analytics.logLinkClick(
                "cme_return_timeout",
                "/cme",
                "cme_survey"
              );

              // After a few seconds redirect to CME page
              router.push("/CME");
            }, 7000);
          } else if (status == 422) {
            console.log("Rerender", html);
            const parser = new DOMParser();
            const doc = parser.parseFromString(html, "text/html");
            const cmeForm = doc.getElementById("cme-claim-form");
            const cmeTitle = doc.getElementById("cme-claim-title");

            this.surveyHtml = cmeForm.innerHTML;
            this.surveyTitle = cmeTitle.innerHTML;

            window.scrollTo(0,0);
          } else {
            // Scroll so error is visible
            window.scrollTo(0, 0);
            console.log("CAUGHT HERE", status);
            this.throwError("ERROR WITH REQUEST");
            throw new Error();
          }
        })
        .catch((err) => {
          this.throwError("ERROR WITH REQUEST");
          throw new Error(err);
        })
    },
    cancelTimeoutGoToCME() {
      // Clear out the timeout set so there isn't a call to transition to CME after 5 seconds
      clearTimeout(this.timeout);

      // Log to analytics
      Analytics.logLinkClick("cme_return_click", "/cme", "cme_survey");

      this.$router.push({ name: "CME" });
    },

    cancelTimeoutGoToSessionPage() {
      // Clear out the timeout set so there isn't a call to transition to CME after 5 seconds
      clearTimeout(this.timeout);

      // Log to analytics
      // Log session Click
      Analytics.logEvent("aad_session_click", {
        search_term: null,
        aad_session_id: 0,
        session_code: this.sessionCode,
        applied_filters: null,
        screen_origin: "cme_survey",
      });

      this.$router.push({
        name: "SessionDetailsCode",
        params: { sessionCode: this.sessionCode },
      });
    },

    throwError: function (errorMessage) {
      this.errorMessage = "Sorry something went wrong";

      // Log error to analytics
      Analytics.logException("cme_error", errorMessage, "cme_survey");
    },
    updateURL: function () {
      this.$router.push({
        path: this.$route.path,
        query: { ...this.$route.query, submitted: true },
      });
    },
  },
  components: {
    AadLoader,
  },
  computed: {
    ...mapGetters(["getMeetingCode", "loggedInUser"]),
  },
  watch: {
    $route() {
      // react to route changes to 'refresh' on new sessionCodes and clearing it...
      this.sessionCode = this.$route.params.sessionCode;
    },
  },
};
</script>

<style scoped>
.submitted-icon {
  color: #03821b;
  width: 100px;
  height: 100px;
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.2s;
}

.fade-enter,
.fade-leave-to {
  opacity: 0;
}
</style>
